import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { cancelLoanOffer, getActiveLoan, getActiveLoans, getBorrowedLoans, getLentLoans, getLoanCollections, getLoanCollectionsDetail, getLoanRequestDetails, getLoanRequests, getLoanStatistics, getNFTsInWallet, getOffersLoans, updateLoanRequest } from '@/api/loans';
import { ILoanTermBaseFields } from '@/api/loans/model';
import { testTokenAddress } from 'smart-contracts/addresses';

import { LoansState, ILoanRequest, ILoanNFT, ILoanCollection, TActiveLoans, IActiveLoan, ILoanRequestDetail, ILoanStatistics, IMyLoan, INFTsPagination, IFetchNFTs, ILoanCollectionQuicknodeDetail } from './model';
import type { RootState } from '../store';

export const myLoanRequests = createAsyncThunk<ILoanRequest[]>('loans/my', async (_, { rejectWithValue }) => {
  try {
    const res = await getLoanRequests({
      params: {
        onlyMy: true,
      },
    });
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchNFTs = createAsyncThunk<INFTsPagination & { spread: boolean }, IFetchNFTs, { state: RootState }>('loans/fetchNFTs', async ({ walletAddress, contractAddresses = testTokenAddress }, { rejectWithValue, dispatch, getState }) => {
  try {
    const {
      loans: { NFTsPagination, myLoanRequests: stateMyLoanRequest },
    } = getState();

    const next = NFTsPagination?.next;
    const res = await getNFTsInWallet(walletAddress, next || null, contractAddresses);

    let myRequestLoans = stateMyLoanRequest;
    if (!stateMyLoanRequest.length) {
      myRequestLoans = await dispatch(myLoanRequests()).unwrap();
    }

    const NFTPaginationLength = NFTsPagination ? NFTsPagination.NFTs.length + 1 : null;
    const data = res.nfts.reduce((agg: ILoanNFT[], asset) => {
      const collectionTokenId = parseInt(asset.token_id, 10);
      const isInMyLoans = !!myRequestLoans.find(({ tokenId }: any) => {
        return tokenId === collectionTokenId;
      });

      const idxNum = agg.length + 1;
      const uniqueId = NFTPaginationLength ? NFTPaginationLength + idxNum : idxNum;
      if (!isInMyLoans) {
        agg.push({ ...asset, id: collectionTokenId, uniqueId });
      }

      return agg;
    }, []);

    return { NFTs: data, next: res.next_cursor, spread: !!next };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLendingOffers = createAsyncThunk('loans/offers', async (_, { rejectWithValue }) => {
  try {
    const res = await getLoanRequests();
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLoanRequest = createAsyncThunk<ILoanRequestDetail, string>('loans/loan', async (id, { rejectWithValue }) => {
  try {
    const res = await getLoanRequestDetails(id);

    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchOpenLoanOffers = createAsyncThunk<ILoanRequest[], string>('loans/loan-open-offers', async (id, { rejectWithValue }) => {
  try {
    const res = await getLoanRequests({
      params: {
        tokenId: id,
        dontIncludeOwner: true,
      },
    });

    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchActiveLoans = createAsyncThunk<TActiveLoans>('loans/active-loans', async (_, { rejectWithValue }) => {
  try {
    const res = await getActiveLoans();
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchActiveLoan = createAsyncThunk<IActiveLoan, { id: string }>('loans/active-loan', async ({ id }, { rejectWithValue }) => {
  try {
    const res = await getActiveLoan(id);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLoanCollections = createAsyncThunk<ILoanCollection[]>('loans/collections', async (_, { rejectWithValue }) => {
  try {
    const data = await getLoanCollections();
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLoanStatistics = createAsyncThunk<ILoanStatistics>('loans/statistics', async (_, { rejectWithValue }) => {
  try {
    const data = await getLoanStatistics();
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLentLoans = createAsyncThunk<IMyLoan[]>('loans/lent', async (_, { rejectWithValue }) => {
  try {
    const data = await getLentLoans();
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchBorrowedLoans = createAsyncThunk<IMyLoan[]>('loans/borrowed', async (_, { rejectWithValue }) => {
  try {
    const data = await getBorrowedLoans();
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchOffersLoans = createAsyncThunk<ILoanRequest[]>('loans/oferrs', async (_, { rejectWithValue }) => {
  try {
    const data = await getOffersLoans();
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const deleteLoanOffer = createAsyncThunk<number, number>('loans/oferrs-delete', async (id: number, { rejectWithValue }) => {
  try {
    await cancelLoanOffer(id);
    return id;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLoanCollectionsDetails = createAsyncThunk<ILoanCollectionQuicknodeDetail, string>('loans/collections-details', async (collectionAddress, { rejectWithValue }) => {
  try {
    const data = await getLoanCollectionsDetail(collectionAddress);

    return data.result[0];
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const putUpdateLoanRequest = createAsyncThunk<ILoanTermBaseFields, ILoanTermBaseFields & { id: string }>('loans/request-update', async ({ id, ...baseFields }, { rejectWithValue }) => {
  try {
    await updateLoanRequest(id, baseFields);

    return baseFields;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

const initialState: LoansState = {
  loading: false,
  lendingLoading: false,
  NFTsPagination: null,
  requestedLoans: [],
  collectionLoan: '',
  myLoanRequests: [],
  lendingOffers: [],
  loan: null,
  openOffers: [],
  activeLoans: [],
  loanLoading: false,
  collections: [
    // {
    //   name: 'nettyWorthEr20',
    //   address: testTokenAddress,
    //   description: 'nettyWorthEr20',
    //   erc1155: false,
    //   erc721: false,
    //   totalSupply: null,
    //   circulatingSupply: null,
    //   genesisBlock: 6667205,
    //   genesisTransaction: '0x8e1b8306f3963896db16cade9084613014cb9f68b9e7e7e5c0cd028465ac0c52',
    // },
  ],
  collectionsLoading: false,
  activeLoan: null,
  activeLoanLoading: false,
  loanStatistics: null,
  loanStatisticsLoading: false,
  lentLoans: null,
  lentLoansLoading: false,
  borrowedLoansLoading: false,
  borrowedLoans: null,
  offersLoansLoading: false,
  offersLoans: null,
  collectionDetails: null,
  collectionDetailsLoading: false,
};

const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    changeReqLoans: (state, action: PayloadAction<ILoanNFT[]>) => {
      state.requestedLoans = action.payload;
    },
    changeCollectionLoan: (state, action) => {
      state.collectionLoan = action.payload;
    },
    deleteNFTsFromBorrower: (state) => {
      state.requestedLoans.forEach(({ id }) => {
        if (!state.NFTsPagination?.NFTs) return;
        const NFTIndex = state.NFTsPagination.NFTs.findIndex(({ id: NFTId }) => NFTId === id);

        state.NFTsPagination.NFTs.splice(NFTIndex, 1);
      });
    },
    setLoanDefaulted: (state, action) => {
      if (!state.lentLoans) return;

      const foundLoan = state.lentLoans.find(({ loanChainId }) => {
        return loanChainId === action.payload;
      });
      if (!foundLoan) return;
      foundLoan.status = 'foreclosed';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNFTs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNFTs.fulfilled, (state, action) => {
        state.loading = false;
        const payloadNfts = action.payload.NFTs;
        const stateNfts = state.NFTsPagination?.NFTs;
        state.NFTsPagination = {
          next: action.payload.next,
          NFTs: action.payload.spread ? (stateNfts ? [...stateNfts, ...payloadNfts] : []) : payloadNfts,
        };
      })
      .addCase(myLoanRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(myLoanRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.myLoanRequests = action.payload;
      })
      .addCase(fetchLendingOffers.pending, (state) => {
        state.lendingLoading = true;
      })
      .addCase(fetchLendingOffers.fulfilled, (state, action) => {
        state.lendingLoading = false;
        state.lendingOffers = action.payload;
      })
      .addCase(fetchLoanRequest.pending, (state) => {
        state.loan = null;
        state.loanLoading = true;
      })
      .addCase(fetchLoanRequest.fulfilled, (state, action) => {
        state.loan = action.payload;
        state.loanLoading = false;
      })
      .addCase(fetchLoanRequest.rejected, (state) => {
        state.loanLoading = false;
      })
      .addCase(fetchOpenLoanOffers.fulfilled, (state, action) => {
        state.openOffers = action.payload;
      })
      .addCase(fetchActiveLoans.fulfilled, (state, action) => {
        state.activeLoans = action.payload;
      })
      .addCase(fetchLoanCollections.pending, (state) => {
        state.collectionsLoading = true;
      })
      .addCase(fetchLoanCollections.fulfilled, (state, action) => {
        state.collectionsLoading = false;
        state.collections = action.payload;
      })
      .addCase(fetchActiveLoan.pending, (state) => {
        state.activeLoanLoading = true;
      })
      .addCase(fetchActiveLoan.fulfilled, (state, action) => {
        state.activeLoanLoading = false;
        state.activeLoan = action.payload;
      })
      .addCase(fetchLoanStatistics.pending, (state) => {
        state.loanStatistics = null;
        state.loanStatisticsLoading = true;
      })
      .addCase(fetchLoanStatistics.fulfilled, (state, action) => {
        state.loanStatistics = action.payload;
        state.loanStatisticsLoading = false;
      })
      .addCase(fetchLentLoans.pending, (state) => {
        state.lentLoansLoading = true;
      })
      .addCase(fetchLentLoans.fulfilled, (state, action) => {
        state.lentLoans = action.payload;
        state.lentLoansLoading = false;
      })
      .addCase(fetchBorrowedLoans.pending, (state) => {
        state.borrowedLoansLoading = true;
      })
      .addCase(fetchBorrowedLoans.fulfilled, (state, action) => {
        state.borrowedLoans = action.payload;
        state.borrowedLoansLoading = false;
      })
      .addCase(fetchOffersLoans.pending, (state) => {
        state.offersLoansLoading = true;
      })
      .addCase(fetchOffersLoans.fulfilled, (state, action) => {
        state.offersLoans = action.payload;
        state.offersLoansLoading = false;
      })
      .addCase(fetchLoanCollectionsDetails.pending, (state) => {
        state.collectionDetailsLoading = true;
      })
      .addCase(fetchLoanCollectionsDetails.fulfilled, (state, action) => {
        state.collectionDetailsLoading = false;
        state.collectionDetails = action.payload;
      })
      .addCase(deleteLoanOffer.fulfilled, (state, action) => {
        if (!state.offersLoans) return;
        const openOffers = [...state.offersLoans];
        openOffers.splice(
          openOffers.findIndex(({ id }) => id === action.payload),
          1
        );

        state.offersLoans = openOffers;
      })
      .addCase(putUpdateLoanRequest.fulfilled, (state, action) => {
        if (!state.loan) return;
        const { ownerRequest } = state.loan;
        state.loan.ownerRequest = {
          ...ownerRequest,
          ...action.payload,
          repay: action.payload.repay.toString(),
        };
      });
  },
});

export const { changeReqLoans, changeCollectionLoan, deleteNFTsFromBorrower, setLoanDefaulted } = loansSlice.actions;
export default loansSlice.reducer;
